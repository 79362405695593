import { useEffect, Suspense, lazy } from "react";
import { Route, Routes, useLocation, Navigate  } from "react-router-dom";
import { useAuth } from './context/AuthContext';
import Loader from "./common/Loader";

const UsersLayout = lazy(() => import("./pages/users/UsersLayout"));
const ProjectLayout = lazy(() => import("./pages/projects/ProjectLayout"));
const MediaLayout = lazy(() => import('./pages/media/MediaLayout'))
const Login = lazy(() => import('./pages/LoginLayout'));
const SignUpLayout = lazy(() => import('./pages/SignUpLayout'));
const EditUserPage = lazy(() => import('./pages/users/ediPage'))
const AddUser = lazy(()=>import('./pages/users/addPage'))
const AddProject = lazy(()=>import('./pages/projects/addPage'))
const EditProjectPage = lazy(()=>import('./pages/projects/ediPage'))
const ViewProjectPage = lazy(()=>import('./pages/projects/ViewPage'))
const MediaAdd = lazy(()=>import('./pages/media/addPage'))


function App() {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={isAuthenticated ? <Navigate to="/" replace /> : <Login />} />
        <Route path="/signup" element={isAuthenticated ? <Navigate to="/" replace /> : <SignUpLayout />} />

        {/* Private Routes */}
        {isAuthenticated ? (
          <>
            <Route path="/" element={<Navigate to="/users" />} />
            <Route path="/users" element={<UsersLayout />} />
            <Route path="/users/adduser" element={<AddUser />} />
            <Route path="/users/edit/:id" element={<EditUserPage/>}/>
            <Route path="/projects" element={<ProjectLayout/>}/>
            <Route path="/projects/addproject" element={<AddProject/>}/>
            <Route path="/projects/edit/:id" element={<EditProjectPage/>}/>
            <Route path='project/:id' element={<ViewProjectPage/>}/>
            <Route path="/media" element={<MediaLayout />} />
            <Route path='/media/add' element={<MediaAdd />} />
            
          </>
        ) : (
          // Redirect to login if not authenticated
          <Route path="*" element={<Navigate to="/login" replace />} />
        )}

        {/* Default route */}
        <Route path="*" element={<Navigate to="/users" />} />
      </Routes>
    </Suspense>
  );
}

export default App